<template>
  <div>
    <b-tabs>
      <!-- Dados Cadastrais -->
      <validation-observer ref="formDadosCadastraisEnderecoBancarios">
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Dados Cadastrais</span>
          </template>

          <b-row>
            <b-col md="3">
              <b-form-group label="Foto" label-for="foto" style="margin-bottom: 0">
                <div class="profile-img">
                  <span v-if="!dados.foto" class="b-avatar badge-secondary rounded" style="width: 160px; height: 120px">
                    <feather-icon icon="UserIcon" fill="currentColor" size="80" />
                  </span>

                  <img v-if="dados.foto" :src="dados.foto.foto" class="img-fluid rounded" style="max-height: 120px" />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nome Completo" label-for="nomeCompleto">
                <validation-provider #default="{ errors }" name="Nome Completo" rules="required">
                  <b-form-input
                    id="nomeCompleto"
                    v-model="dados.nomeCompleto"
                    type="text"
                    :disabled="true"
                    placeholder="Nome Completo"
                    @keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nome Social" label-for="nomeSocial">
                <b-form-input
                  id="nomeSocial"
                  v-model="dados.nomeSocial"
                  :disabled="true"
                  type="text"
                  placeholder="Nome Social"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="CPF" label-for="cpf">
                <validation-provider #default="{ errors }" name="CPF" rules="required">
                  <cleave
                    id="cpf"
                    v-model="dados.cpf"
                    type="text"
                    :disabled="true"
                    placeholder="CPF"
                    :raw="false"
                    :options="options.cleaveCPF"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Data Nascimento" label-for="dataNascimento">
                <validation-provider #default="{ errors }" name="Data Nascimento" rules="required">
                  <cleave
                    id="dataNascimento"
                    v-model="dados.dataNascimento"
                    class="form-control"
                    type="text"
                    :disabled="true"
                    placeholder="DD/MM/AAAA"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Sexo" label-for="sexo">
                <validation-provider #default="{ errors }" name="Sexo" rules="required">
                  <b-form-select v-model="dados.sexo" name="sexo" :disabled="true" :options="sexoOpcoes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Gênero" label-for="genero">
                <validation-provider #default="{ errors }" name="Gênero" rules="required">
                  <b-form-select v-model="dados.genero" name="genero" :disabled="true" :options="generoOpcoes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Estado Civil" label-for="estadoCivil">
                <validation-provider #default="{ errors }" name="Estado Civil" rules="required">
                  <b-form-select v-model="dados.estadoCivil" name="estadoCivil" :disabled="true" :options="estadoCivilOpcoes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome da Mãe" label-for="nomeMae">
                <validation-provider #default="{ errors }" name="Nome da Mãe" rules="required">
                  <b-form-input
                    id="nomeMae"
                    v-model="dados.nomeMae"
                    type="text"
                    :disabled="true"
                    placeholder="Nome da Mãe"
                    @keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nome do Pai" label-for="nomePai">
                <b-form-input
                  id="nomePai"
                  v-model="dados.nomePai"
                  type="text"
                  :disabled="true"
                  placeholder="Nome do Pai"
                  @keypress="isLetter($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Nacionalidade" label-for="nacionalidade">
                <validation-provider #default="{ errors }" name="Nacionalidade" rules="required">
                  <v-select-pt
                    v-model="dados.nacionalidade"
                    name="nacionalidade"
                    :options="nacionalidade"
                    :disabled="true"
                    :reduce="(option) => option.codigoFederal"
                    label="nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="dados.nacionalidade === BRAZIL_COUNTRY_CODE">
              <b-form-group label="UF Naturalidade" label-for="ufNaturalidade">
                <validation-provider #default="{ errors }" name="UF Naturalidade" rules="required">
                  <b-form-select
                    id="ufNaturalidade"
                    v-model="dados.ufNaturalidade"
                    name="ufNaturalidade"
                    :disabled="true"
                    :options="ufOpcoes"
                    @change="carregarCidadeNaturalidade"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="dados.nacionalidade === BRAZIL_COUNTRY_CODE">
              <b-form-group label="Naturalidade" label-for="naturalidade">
                <validation-provider #default="{ errors }" name="Naturalidade" rules="required">
                  <v-select-pt
                    v-model="dados.naturalidade"
                    name="naturalidade"
                    :disabled="true"
                    :options="cidadeNaturalidade"
                    :reduce="(option) => option.nome"
                    label="nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" v-if="dados.nacionalidade != BRAZIL_COUNTRY_CODE">
              <b-form-group label="Naturalidade" label-for="naturalidade">
                <validation-provider #default="{ errors }" name="Naturalidade" rules="required">
                  <b-form-input
                    id="naturalidade"
                    type="text"
                    :disabled="true"
                    placeholder="Naturalidade"
                    v-model="dados.naturalidade"
                    v-bloqueioCaractereMask="{ tamanho: 70 }"
                    v-on:keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="RG Nº" label-for="rgNumero">
                <validation-provider #default="{ errors }" name="RG Nº" rules="required">
                  <cleave
                    id="rgNumero"
                    v-model="dados.rgNumero"
                    type="text"
                    placeholder="RG Nº"
                    :disabled="true"
                    :raw="false"
                    :options="options.cleaveRG"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Data Emissão" label-for="rgDataEmissao">
                <validation-provider #default="{ errors }" name="Data Emissão" rules="required">
                  <cleave
                    id="rgDataEmissao"
                    v-model="dados.rgDataEmissao"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    :disabled="true"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="RG Órgão Emissor" label-for="rgOrgaoEmissor">
                <validation-provider #default="{ errors }" name="RG Órgão Emissor" rules="required">
                  <b-form-input
                    id="rgOrgaoEmissor"
                    v-model="dados.rgOrgaoEmissor"
                    :disabled="true"
                    type="text"
                    placeholder="RG Órgão Emissor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="UF de Expedição do RG" label-for="rgUF">
                <validation-provider #default="{ errors }" name="UF de Expedição do RG" rules="required">
                  <b-form-select id="rgUF" v-model="dados.rgUF" :disabled="true" name="rgUF" :options="ufOpcoes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Telefone" label-for="telefone">
                <validation-provider #default="{ errors }" name="Telefone" rules="required">
                  <cleave
                    id="telefone"
                    v-model="dados.telefone"
                    type="text"
                    placeholder="Telefone"
                    :disabled="true"
                    :raw="false"
                    :options="options.cleavePhone"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9">
              <b-form-group label="E-mail" label-for="email">
                <validation-provider #default="{ errors }" name="E-mail" rules="required">
                  <b-form-input id="email" v-model="dados.email" :disabled="true" type="text" placeholder="E-mail" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-group v-slot="{ ariaDescribedby }" label="Portador de Moléstia Grave?" label-for="portadorMolestiaGrave">
                <validation-provider #default="{ errors }" name="Portador de Moléstia Grave?" rules="required">
                  <b-form-radio-group
                    id="portadorMolestiaGrave"
                    v-model="dados.portadorMolestiaGrave"
                    :options="optionsSimNao"
                    :disabled="true"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group v-slot="{ ariaDescribedby }" label="Portador de Doença Incapacitante?" label-for="email">
                <validation-provider #default="{ errors }" name="Portador de Doença Incapacitante?" rules="required">
                  <b-form-radio-group
                    id="portadorDoencaIncapacitante"
                    v-model="dados.portadorDoencaIncapacitante"
                    :options="optionsSimNao"
                    :disabled="true"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group v-slot="{ ariaDescribedby }" label="Falecido?" label-for="falecido">
                <validation-provider #default="{ errors }" name="Falecido?" rules="required">
                  <b-form-radio-group
                    id="falecido"
                    v-model="dados.falecido"
                    :options="optionsSimNao"
                    :disabled="true"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group v-if="dados.falecido" label="Data do Óbito" label-for="dataObito">
                <validation-provider #default="{ errors }" name="Data do Óbito" rules="required">
                  <b-form-datepicker
                    v-model="dados.dataObito"
                    :disabled="true"
                    button-variant="primary"
                    class="datePickerWithInput"
                    right
                    :initial-date="new Date()"
                    :state="errors[0] ? false : null"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    aria-controls="dataObito"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="dados.falecido" class="mt-2">
            <b-col sm="12" md="3">
              <div id="app" class="flex w-full items-center justify-center">
                <div class="p-12 bg-gray-100" style="align-items: center">
                  <b-button id="certidaoObito" class="w-100" variant="primary" @click="downloadCertidao">
                    <b-spinner small v-if="isBusy" />
                    <span v-if="!isBusy" class="align-middle mr-05">Download Certidão de Óbito</span>
                    <feather-icon v-if="!isBusy" icon="DownloadIcon" size="14" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Endereço -->
        <b-tab>
          <template #title>
            <feather-icon icon="MapPinIcon" />
            <span>Endereço</span>
          </template>
          <b-row>
            <b-col md="2">
              <b-form-group label="CEP" label-for="cepEndereco">
                <validation-provider #default="{ errors }" name="CEP" rules="required">
                  <cleave
                    id="cepEndereco"
                    v-model="dados.cepEndereco"
                    type="text"
                    placeholder="CEP"
                    maxlength="10"
                    :disabled="true"
                    :raw="false"
                    :options="options.cleaveCEP"
                    class="form-control"
                    @keyup.native="buscarCEP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Logradouro" label-for="logradouroEndereco">
                <validation-provider #default="{ errors }" name="Logradouro" rules="required">
                  <b-form-input
                    id="logradouroEndereco"
                    v-model="dados.logradouroEndereco"
                    type="text"
                    :disabled="true"
                    placeholder="Logradouro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número" label-for="numeroEndereco">
                <validation-provider #default="{ errors }" name="Número" rules="required">
                  <b-form-input
                    id="numeroEndereco"
                    v-model="dados.numeroEndereco"
                    :disabled="true"
                    type="text"
                    placeholder="Número"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Complemento" label-for="complementoEndereco">
                <b-form-input
                  id="complementoEndereco"
                  v-model="dados.complementoEndereco"
                  type="text"
                  :disabled="true"
                  placeholder="Complemento"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Bairro" label-for="bairroEndereco">
                <validation-provider #default="{ errors }" name="Bairro" rules="required">
                  <b-form-input
                    id="bairroEndereco"
                    v-model="dados.bairroEndereco"
                    :disabled="true"
                    type="text"
                    placeholder="Bairro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="UF" label-for="ufEndereco">
                <validation-provider #default="{ errors }" name="UF" rules="required">
                  <b-form-select
                    v-model="dados.ufEndereco"
                    name="ufEndereco"
                    :options="ufOpcoes"
                    :disabled="true"
                    @change="carregarCidadeEndereco"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Cidade" label-for="cidadeEndereco">
                <validation-provider #default="{ errors }" name="Cidade" rules="required">
                  <v-select-pt
                    v-model="dados.cidadeEndereco"
                    name="cidadeEndereco"
                    :options="cidadeEndereco"
                    :disabled="true"
                    :reduce="(option) => option.nome"
                    label="nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small class="text-primary d-block"
                  >Digite o CEP, ou selecione a UF, para que as cidades sejam carregadas</small
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Dados Bancários -->
        <b-tab>
          <template #title>
            <feather-icon icon="DollarSignIcon" />
            <span>Dados Bancários</span>
          </template>
          <b-row>
            <b-col md="6">
              <b-form-group label="Banco" label-for="dadoBancarioBanco">
                <validation-provider #default="{ errors }" name="Banco" rules="required">
                  <v-select-pt
                    v-model="dados.bancoId"
                    name="dadoBancarioBanco"
                    :options="banco"
                    :disabled="true"
                    :reduce="(option) => option.id"
                    label="nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Agência" label-for="dadoBancarioAgencia">
                <validation-provider #default="{ errors }" name="Agência" rules="required">
                  <cleave
                    id="dadoBancarioAgencia"
                    v-model="dados.dadoBancarioAgencia"
                    type="text"
                    placeholder="Agência"
                    maxlength="4"
                    :disabled="true"
                    :raw="false"
                    :options="options.cleaveAgencia"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="DV" label-for="dadoBancarioAgenciaDV">
                <validation-provider #default="{ errors }" name="Agencia DV" rules="required">
                  <b-form-input
                    id="dadoBancarioAgenciaDV"
                    v-model="dados.dadoBancarioAgenciaDV"
                    :disabled="true"
                    type="text"
                    placeholder="DV"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Conta Corrente" label-for="dadoBancarioConta">
                <validation-provider #default="{ errors }" name="Conta Corrente" rules="required">
                  <b-form-input
                    id="dadoBancarioConta"
                    v-model="dados.dadoBancarioConta"
                    type="text"
                    :disabled="true"
                    placeholder="Conta Corrente"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Conta Corrente DV" label-for="dadoBancarioContaDV">
                <validation-provider #default="{ errors }" name="Conta Corrente DV" rules="required">
                  <b-form-input
                    id="dadoBancarioContaDV"
                    v-model="dados.dadoBancarioContaDV"
                    type="text"
                    :disabled="true"
                    placeholder="Conta Corrente DV"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="OP" label-for="dadoBancarioContaOP">
                <validation-provider #default="{ errors }" name="OP" rules="required">
                  <cleave
                    id="dadoBancarioContaOP"
                    v-model="dados.dadoBancarioContaOP"
                    type="text"
                    placeholder="OP"
                    :disabled="true"
                    maxlength="3"
                    :raw="false"
                    :options="options.cleaveOp"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Tipo de Conta" label-for="dadoBancarioFormaDePagamento">
                <validation-provider #default="{ errors }" name="Forma de Pagamento" rules="required">
                  <v-select-pt
                    v-model="dados.dadoBancarioFormaDePagamento"
                    name="dadoBancarioFormaDePagamento"
                    :disabled="true"
                    :options="formasDePagamento"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Tipo de Chave Pix" label-for="tipoChavePix">
                <validation-provider #default="{ errors }" name="Tipo de Chave Pix">
                  <v-select-pt
                    v-model="dados.tipoChavePix"
                    placeholder="Selecione"
                    name="tipoChavePix"
                    :disabled="true"
                    :options="tipoChavePixOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group v-if="dados.tipoChavePix" label="Chave Pix" label-for="chavePix">
                <validation-provider #default="{ errors }" name="Chave Pix" rules="required">
                  <Cleave
                    v-if="(dados.tipoChavePix == 'cpf') | (dados.tipoChavePix == 'cnpj')"
                    id="chavePix"
                    v-model="dados.chavePix"
                    type="text"
                    :disabled="true"
                    :placeholder="dados.chavePix.length <= 11 ? '000.000.000-00' : '00.000.000/0001-00'"
                    :raw="false"
                    :options="dados.chavePix.length <= 11 ? options.cleaveCPF : options.cleaveCNPJ"
                    class="form-control"
                    @input="removePontos"
                  />

                  <cleave
                    v-else-if="dados.tipoChavePix == 'email'"
                    id="chavePix"
                    v-model="dados.chavePix"
                    type="email"
                    :disabled="true"
                    placeholder="exemplo@exemplo.com"
                    :options="options.cleaveEmail"
                    :raw="false"
                    class="form-control"
                  />

                  <cleave
                    v-else-if="dados.tipoChavePix == 'telefone'"
                    id="chavePix"
                    v-model="dados.chavePix"
                    type="tel"
                    :disabled="true"
                    placeholder="(99) 9 9999-9999"
                    :raw="false"
                    :options="options.cleavePhone"
                    class="form-control"
                    @input="removePontos"
                  />

                  <b-form-input
                    v-else
                    id="chavePix"
                    v-model="dados.chavePix"
                    type="text"
                    :disabled="true"
                    placeholder="Chave aleatória"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </validation-observer>

      <!-- Segurado/Beneficiário -->
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Segurado/Beneficiário</span>
        </template>
        <ValidationObserver ref="formularioSegurado">
          <b-row>
            <b-col md="4">
              <b-form-group label="Matrícula" label-for="matricula">
                <validation-provider #default="{ errors }" name="Matrícula" rules="required">
                  <b-form-input
                    id="matricula"
                    v-model="segurado.matricula"
                    type="text"
                    :disabled="true"
                    placeholder="Matrícula"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Órgão" label-for="orgao">
                <validation-provider #default="{ errors }" name="Órgão" rules="required">
                  <v-select-pt
                    v-model="segurado.orgao"
                    name="orgao"
                    :options="orgao"
                    :disabled="true"
                    :reduce="(option) => option.id"
                    label="nome"
                    @option:selected="limpaGrupoFolha"
                    @search:blur="carregarGrupoFolha"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Grupo Folha" label-for="grupoFolha">
                <validation-provider #default="{ errors }" name="Grupo Folha" rules="required">
                  <v-select-pt
                    v-model="segurado.grupoFolhaId"
                    name="grupoFolha"
                    :disabled="true"
                    :options="gruposFolha"
                    :reduce="(option) => option.id"
                    label="nomeDoGrupo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Remuneração (R$)" label-for="remuneracao">
                <validation-provider #default="{ errors }" name="Remuneração" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="remuneracao"
                      v-model="segurado.remuneracao"
                      v-number="number"
                      :disabled="true"
                      type="text"
                      placeholder="Remuneração (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Margem Utilizada (R$)" label-for="margemUtilizada">
                <validation-provider #default="{ errors }" name="Margem Utilizada" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="margemUtilizada"
                      v-model="segurado.margemUtilizada"
                      v-number="number"
                      :disabled="true"
                      type="text"
                      placeholder="Margem Utilizada (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Margem Disponível (R$)" label-for="margemDisponivel">
                <validation-provider #default="{ errors }" name="Margem Disponível" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="margemDisponivel"
                      v-model="segurado.margemDisponivel"
                      v-number="number"
                      :disabled="true"
                      type="text"
                      placeholder="Margem Disponível (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Categoria" label-for="categoria">
                <validation-provider #default="{ errors }" name="Categoria" rules="required">
                  <b-form-select v-model="segurado.categoria" :disabled="true" name="categoria" :options="categoriaOpcoes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="segurado.categoria == 'Pensionista'" md="auto">
              <b-form-group id="divEhVitalicio" label="É Vitalício?" label-for="ehVitalicio">
                <b-form-checkbox
                  id="ehVitalicio"
                  v-model="segurado.ehVitalicio"
                  switch
                  name="ehVitalicio"
                  :disabled="true"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="segurado.categoria == 'Efetivo' || segurado.categoria == 'Estável'" md="4">
              <b-form-group label="Data de Admissão" label-for="dataAdmissao">
                <validation-provider #default="{ errors }" name="Data de Admissão" rules="required">
                  <cleave
                    id="dataAdmissao"
                    v-model="segurado.dataAdmissao"
                    class="form-control"
                    type="text"
                    :disabled="true"
                    placeholder="DD/MM/AAAA"
                    :options="options.cleaveDate"
                    :raw="false"
                    @blur="validaDataSegurado('dataAdmissao')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="segurado.categoria == 'Efetivo' || segurado.categoria == 'Estável'" md="4">
              <b-form-group v-slot="{ ariaDescribedby }" label="Exonerado?" label-for="Exonerado">
                <validation-provider #default="{ errors }" name="Exonerado?" rules="required">
                  <b-form-radio-group
                    id="exonerado"
                    v-model="segurado.exonerado"
                    :options="optionsSimNao"
                    :disabled="true"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="segurado.categoria == 'Aposentado' || segurado.categoria == 'Pensionista'" md="3">
              <b-form-group label="Data Início do Benefício" label-for="dataInicioBeneficio">
                <validation-provider #default="{ errors }" name="Data Início do Benefício" rules="required">
                  <cleave
                    id="dataInicioBeneficio"
                    v-model="segurado.dataInicioBeneficio"
                    class="form-control"
                    type="text"
                    :disabled="true"
                    placeholder="DD/MM/AAAA"
                    :options="options.cleaveDate"
                    :raw="false"
                    @blur="validaDataSegurado('dataInicioBeneficio')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="segurado.categoria == 'Aposentado'" md="5">
              <b-form-group label="Tipo de benefício" label-for="tipoBeneficio">
                <validation-provider #default="{ errors }" name="tipoBeneficio" rules="required">
                  <b-form-select
                    id="tipoBeneficio"
                    v-model="segurado.tipoBeneficio"
                    name="tipoBeneficio"
                    :disabled="true"
                    :options="beneficioOpcoes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="segurado.categoria == 'Pensionista' && segurado.ehVitalicio == false" md="3">
              <b-form-group label="Data Fim do Benefício" label-for="dataFimBeneficio">
                <validation-provider #default="{ errors }" name="Data Fim do Benefício" rules="required">
                  <cleave
                    id="dataFimBeneficio"
                    v-model="segurado.dataFimBeneficio"
                    class="form-control"
                    type="text"
                    :disabled="true"
                    placeholder="DD/MM/AAAA"
                    :options="options.cleaveDate"
                    :raw="false"
                    @blur="validaDataSegurado('dataFimBeneficio')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="this.dados.id">
              <b-form-group label="Bloqueado judicialmente?" label-for="bloqueioJudicial" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Bloqueado judicialmente" rules="required">
                  <b-form-radio-group
                    id="bloqueioJudicial"
                    v-model="segurado.bloqueioJudicial"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    :disabled="true"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="this.dados.id && segurado.bloqueioJudicial">
              <b-form-group label="Data da suspensão judicial" label-for="dataBloqueioJudicial">
                <validation-provider #default="{ errors }" name="Data da suspensão judicial" rules="required">
                  <cleave
                    id="dataBloqueioJudicial"
                    class="form-control"
                    :disabled="true"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    v-model="segurado.dataBloqueioJudicial"
                    :options="options.cleaveDate"
                    :raw="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </ValidationObserver>
        <!-- Actions -->
        <b-row>
          <b-col md="4">
            <b-form-group label=" " label-for="btnEraseFormSegurado">
              <b-button
                v-ripple.400="'rgba(40, 74, 199, 0.4)'"
                name="btnEraseFormSegurado"
                class="ml-1"
                variant="secondary"
                :disabled="true"
                @click="eraseFormSegurado()"
              >
                Limpar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-table striped :items="tomadorSegurados" :fields="fieldsTableSegurados">
            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>
                <b-dropdown-item @click="visualizarSegurado(row.item)">
                  <feather-icon icon="EditIcon" size="16" />
                  <span class="align-middle ml-50">Visualizar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(margemDisponivel)="row">
              {{ formatarValor(Number(row.item.margemDisponivel)) }}
            </template>
            <template #cell(remuneracao)="row">
              {{ formatarValor(Number(row.item.remuneracao)) }}
            </template>
          </b-table>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Arquivos</span>
        </template>
        <b-row>
          <b-col>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="primary" :disabled="true"> Anexar Arquivo </b-button>
          </b-col>
        </b-row>
        <div class="table-overflow">
          <b-row class="mt-1">
            <b-table v-if="itemsArquivos.length" :items="itemsArquivos" :fields="fieldsTableArquivos" :busy="isBusyArquivos">
              <template #cell(opcoes)="row">
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      title="Este botão contém as opções disponíveis para este registro"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </template>
                  <b-dropdown-item @click="downloadArquivo(row.item)">
                    <feather-icon icon="EyeIcon" size="16" />
                    <span class="align-middle ml-50">Visualizar</span>
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="true">
                    <feather-icon icon="XIcon" size="16" />
                    <span class="align-middle ml-50">Excluir</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal id="modal-foto" ref="modal-foto" centered size="lg" title="Captura de Foto" hide-footer no-close-on-backdrop>
      <div class="camera-canvas">
        <video v-if="isCameraOpen" ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay />
        <canvas v-show="!isCameraOpen" ref="canvas" :width="canvasWidth" :height="canvasHeight" />
      </div>

      <template>
        <b-button v-show="isCameraOpen" size="sm" variant="primary" @click="capture()"> Tirar Foto </b-button>
        <b-button v-show="!isCameraOpen" size="sm" variant="success" :disabled="loading" @click="confirmarFoto()">
          Confirmar Foto
        </b-button>
        <b-button v-show="!isCameraOpen" size="sm" class="ml-1" variant="danger" @click="toggleCamera()">
          Limpar Foto
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, valor } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import {
    Ufs,
    isLetter,
    formatarData,
    formatarDataGlobal,
    isCPFValid,
    isNumber,
    toNumeric,
    validarData,
    formatarValor,
    formatarParaNumber,
    msgError,
  } from '@/libs/utils'
  import { VueSelect } from 'vue-select'
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'
  import { getDownloadArquivoAws } from '@/utils/aws/getDownloadArquivoAws'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      formatarData,
      formatarDataGlobal,
      isNumber,
      toNumeric,
      validarData,
      Cleave,
      formatarValor,
      VueSelect,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        required,
        isLetter,
        isCPFValid,
        isNumber,
        toNumeric,
        validarData,
        formatarValor,

        beneficioOpcoes: [
          { value: 'aposentadoriaPorIncapacidadePermanente', text: 'Aposentadoria por Incapacidade Permanente' },
          { value: 'aposentadoriaPorTempoDeContribuição', text: 'Aposentadoria por Tempo de Contribuição' },
          { value: 'aposentadoriaCompulsoria', text: 'Aposentadoria Compulsória' },
          { value: 'aposentadoriaPorIdade', text: 'Aposentadoria por Idade' },
          { value: 'aposentadoriaPorTempoDeServico', text: 'Aposentadoria por Tempo de Serviço' },
        ],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
        },
        loading: false,
        isCreateSegurado: true,
        number: 0.0,
        canvasHeight: 255,
        canvasWidth: 340,
        isCameraOpen: false,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        formasDePagamento: [
          { value: 'creditoEmContaCorrente', text: 'Crédito em Conta Corrente' },
          { value: 'creditoEmContaPoupanca', text: 'Crédito em Conta Poupança' },
        ],
        tipoChavePixOpcoes: [
          { value: 'cpf', text: 'CPF / CNPJ' },
          { value: 'email', text: 'E-mail' },
          { value: 'telefone', text: 'Telefone' },
          { value: 'aleatoria', text: 'Aleatória' },
        ],
        sexoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'M', text: 'Masculino' },
          { value: 'F', text: 'Feminino' },
        ],
        generoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'T', text: 'Transgênero' },
          { value: 'C', text: 'Cisgênero' },
          { value: 'N', text: 'Não-Binário' },
        ],
        estadoCivilOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Solteiro(a)', text: 'Solteiro(a)' },
          { value: 'Casado(a)', text: 'Casado(a)' },
          { value: 'Separado(a)', text: 'Separado(a)' },
          { value: 'Divorciado(a)', text: 'Divorciado(a)' },
          { value: 'Viúvo(a)', text: 'Viúvo(a)' },
        ],
        tomadorSegurados: [],
        nacionalidade: [],
        banco: [],
        cidadeNaturalidade: [],
        cidadeEndereco: [],
        ufOpcoes: Ufs,
        ufOpcoes2: Ufs,
        orgao: [],
        categoriaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Efetivo', text: 'Efetivo' },
          { value: 'Estável', text: 'Estável' },
          { value: 'Pensionista', text: 'Pensionista' },
          { value: 'Aposentado', text: 'Aposentado' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 3],
            numericOnly: true,
          },
          cleaveEmail: {
            blocks: [100],
          },
          cleaveCNPJ: {
            delimiters: ['.', '.', '/', '-'],
            numericOnly: true,
            blocks: [2, 3, 3, 4, 2],
          },
          cleaveRG: {
            blocks: [8],
            numericOnly: true,
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleavePhone: {
            numericOnly: true,
            delimiters: ['(', ')', ' ', '-'],
            blocks: [0, 2, 0, 5, 4],
          },
          cleaveCEP: {
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3],
          },
          cleaveAgencia: {
            numericOnly: true,
            blocks: [4],
          },
          cleaveOp: {
            blocks: [3],
          },
        },
        optionsSimNao: [
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ],
        pageOptions: [25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        fieldsTableSegurados: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'matricula', label: 'Matrícula' },
          { key: 'situacaoPrevidenciaria', label: 'Categoria' },
          { key: 'margemDisponivel', label: 'Margem Disponível' },
          { key: 'remuneracao', label: 'Remuneração' },
          { key: 'orgao.nome', label: 'Órgão' },
        ],
        fields: [
          {
            key: 'opcoes',
            label: 'Opções',
          },
          {
            key: 'avatar',
            label: '',
          },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            sortable: true,
            formatter: (value, key, item) => formatarData(value),
          },
          'sexo',
          { key: 'matricula', label: 'Matrícula', sortable: true },
          { key: 'orgao.nome', label: 'Órgão', sortable: true },
          'categoria',
        ],
        items: [],
        acesso: {},
        segurado: {
          categoria: '',
          ehVitalicio: false,
          remuneracao: null,
          margemDisponivel: null,
          margemUtilizada: null,
          dataAdmissao: '',
          dataInicioBeneficio: '',
          dataFimBeneficio: '',
          orgao: null,
          ordaoId: null,
          grupoFolhaId: null,
          tipoBeneficio: null,
          exonerado: false,
          bloqueioJudicial: false,
          dataBloqueioJudicial: '',
        },
        listaSegurados: [],
        dados: {
          foto: null,
          nacionalidade: 76,
          sexo: '',
          genero: '',
          portadorMolestiaGrave: false,
          portadorDoencaIncapacitante: false,
          falecido: false,
          exonerado: false,
          tipoChavePix: '',
          chavePix: '',
          categoria: '',
          naturalidade: '',

          cidadeEndereco: {
            nome: null,
          },
          rgUF: '',
          ufNaturalidade: '',
          foto: null,
          estadoCivil: '',
          dadoBancarioAgencia: '',
          dadoBancarioAgenciaDV: '',
        },
        tomadorWithOrg: {},
        certidaoObitoAnexada: null,
        excluirSegurados: [],
        categoria: [
          {
            Ativo: 'primary',
            Aposentado: 'light-success',
            Pensionista: 'light-danger',
          },
        ],
        gruposFolha: [],
        itemsArquivos: [],
        fieldsTableArquivos: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'nome', label: 'Nome' },
          { key: 'nomeArquivo', label: 'Arquivo' },
          { key: 'usuario', label: 'Usuário' },
          { key: 'createdAt', label: 'Data de Criação', formatter: (value) => (value ? formatarData(value) : '') },
        ],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.CadastroTomadorEditar = true
      } else {
        this.acesso.CadastroTomadorEditar = this.userData.GrupoAcesso.CadastroTomadorEditar
      }
      this.carregarPaises()
      this.carregarOrgaos()
      this.carregarBancos()

      if (this.parametro) {
        this.carregarDados(this.parametro.id)
        this.carregarGridArquivos(this.parametro.id)
      }

      // TODO: Ver uma forma melhor de fazer isso sem usar gambiarra
      const inputDigitoAgencia = document.getElementById('dadoBancarioAgenciaDV')
      inputDigitoAgencia.addEventListener('input', (event) => {
        const forbiddenLetter = /[^0-9X]/gi // Letra que você deseja proibir
        const currentValue = event.target.value
        const newValue = currentValue.replace(forbiddenLetter, '')
        event.target.value = newValue.substring(0, 1)
      })

      const inputDigitoConta = document.getElementById('dadoBancarioContaDV')
      inputDigitoConta.addEventListener('input', (event) => {
        const forbiddenLetter = /[^0-9X]/gi // Letra que você deseja proibir
        const currentValue = event.target.value
        const newValue = currentValue.replace(forbiddenLetter, '')
        event.target.value = newValue.substring(0, 1)
      })
    },
    methods: {
      removePontos() {
        // Remove pontos da string
        this.dados.chavePix = this.dados.chavePix.replace(/[./( )-]/g, '')
      },
      buscarCEP() {
        if (this.dados.cepEndereco.length == 10) {
          const dadosPesquisaCep = {
            cep: this.dados.cepEndereco.replace(/\D/g, ''),
            tomadorId: this.dados.id ? this.dados.id : null,
          }

          useJwt
            .post('cadastro/tomador/GetBuscaCepTomador', dadosPesquisaCep)
            .then(async (response) => {
              if (response.data.status) {
                if (response.data.status == 'erro') this.$message.error(response.data.mensagem)
              } else {
                await this.carregarCidadeEndereco(response.data.uf)
                this.dados = {
                  ...this.dados,
                  complemento: response.data.complemento,
                  logradouroEndereco: response.data.logradouro,
                  bairroEndereco: response.data.bairro,
                  ufEndereco: response.data.uf,
                  cidadeEndereco: response.data.localidade,
                }
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async carregarCidadeEndereco(item) {
        return new Promise((resolve, reject) => {
          useJwt
            .get(`utils/cidades/${item}`)
            .then((response) => {
              this.cidadeEndereco = response.data
              return resolve(response.data)
            })
            .catch((error) => {
              console.error(error)
              return reject()
            })
        })
      },
      carregarGrupoFolha() {
        const orgaoId = this.segurado?.orgao?.id || this.segurado?.orgao
        if (orgaoId) {
          useJwt
            .pesquisar('cadastro/grupoFolha/GetPesquisarPorOrgao', {
              orgaoId,
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              this.gruposFolha = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarCidadeNaturalidade(item) {
        return new Promise((resolve, reject) => {
          useJwt
            .get(`utils/cidades/${item}`)
            .then((response) => {
              this.cidadeNaturalidade = response.data
              return resolve(response.data)
            })
            .catch((error) => {
              console.error(error)
              return reject()
            })
        })
      },
      carregarPaises() {
        useJwt
          .get('utils/paises')
          .then((response) => {
            this.nacionalidade = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarBancos() {
        useJwt
          .get('utils/bancos')
          .then((response) => {
            this.banco = response.data
            this.banco.forEach((banco) => {
              if (banco.codigo) banco.nome = `${String(banco.codigo)} - ${String(banco.nome)}`
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async carregarDados(item) {
        if (item) {
          useJwt
            .edit('cadastro/tomador', item)
            .then(async (response) => {
              this.dados = {
                ...response.data,
                dataNascimento: response.data.dataNascimento ? formatarData(response.data.dataNascimento) : '',
                rgDataEmissao: response.data.rgDataEmissao ? formatarData(response.data.rgDataEmissao) : '',
                dataAdmissao: response.data.dataAdmissao ? formatarData(response.data.dataAdmissao) : '',
                dataFimBeneficio: response.data.dataFimBeneficio ? formatarData(response.data.dataFimBeneficio) : '',
                portadorMolestiaGrave: !!response.data.portadorMolestiaGrave,
                portadorDoencaIncapacitante: !!response.data.portadorDoencaIncapacitante,
                falecido: !!response.data.falecido,
                dataObito: response.data.dataObito ? response.data.dataObito : '',
                exonerado: !!response.data.exonerado,
                tipoChavePix: response.data.tipoChavePix ? response.data.tipoChavePix : '',
                chavePix: response.data.chavePix ? response.data.chavePix : '',
              }

              if (this.dados.certidaoObito) {
                this.certidaoObitoAnexada = this.dados.CertidaoObitoTomador
              }
              this.tomadorSegurados = response.data.segurado

              if (response.data.paisId) {
                this.dados.nacionalidade = this.nacionalidade.find((pais) => pais.codigoFederal == response.data.paisId)
              }
              if (this.dados.ufNaturalidade && this.dados.naturalidade != BRAZIL_COUNTRY_CODE) {
                this.carregarCidadeNaturalidade(this.dados.ufNaturalidade).then((resultado) => {
                  this.dados.naturalidade = resultado.find((cidade) => cidade.nome == response.data.naturalidade)?.nome
                })
              }

              if (this.dados.ufEndereco) {
                this.carregarCidadeEndereco(this.dados.ufEndereco).then((resultado) => {
                  this.dados.cidadeEndereco = resultado.find((cidade) => cidade.nome == response.data.cidadeEndereco)?.nome
                })
              }

              this.carregarSegurados()
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async salvar() {
        const invalid = !(await this.$refs.formDadosCadastraisEnderecoBancarios.validate())
        if (invalid) {
          this.$message.error('Preencha todos os campos obrigatórios!')
          return
        }

        if (this.segurado.id) {
          this.$message.error('Existe uma edição de segurado pendente de finalização!')
          return
        }

        this.loading = true
        const dadosInsert = {
          ...this.dados,
          dataNascimento: this.dados.dataNascimento ? formatarDataGlobal(this.dados.dataNascimento) : null,
          rgDataEmissao: this.dados.rgDataEmissao ? formatarDataGlobal(this.dados.rgDataEmissao) : null,
          dataAdmissao: this.dados.dataAdmissao ? formatarDataGlobal(this.dados.dataAdmissao) : null,
          dataFimBeneficio: this.dados.dataFimBeneficio ? formatarDataGlobal(this.dados.dataFimBeneficio) : null,
          segurados: this.tomadorSegurados,
          naturalidade: this.dados.naturalidade,
          cidadeEndereco: this.dados.cidadeEndereco,
          nacionalidade: this.dados.nacionalidade,
          institutoId: this.userData.institutoSelecionado,
          tipoChavePix: this.dados.tipoChavePix,
          chavePix: this.dados.chavePix,
        }

        if (dadosInsert?.segurado) delete dadosInsert.segurado
        if (dadosInsert?.segurados?.id) delete dadosInsert.segurados.id

        const { foto, ...dados } = dadosInsert

        if (!this.dados.id) {
          useJwt
            .post('cadastro/tomador', dados)
            .then((response) => {
              if (this.dados.foto) {
                useJwt
                  .post('cadastro/tomador/cadastrarFoto', {
                    foto: this.dados.foto.foto,
                    tomadorId: response.data.id,
                  })
                  .then((response) => {
                    this.loading = false
                    this.$emit('atualizaGrid')
                    this.mostrarMsgModal('Cadastrado com sucesso!')
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              } else {
                this.mostrarMsgModal('Cadastrado com sucesso!')
              }
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Houve um erro ao cadastrar o tomador')
            })
        } else {
          useJwt
            .update('cadastro/tomador', this.dados.id, dados)
            .then((response) => {
              if (response.data.status === 'erro') {
                this.$message.error(response.data.mensagem)
                return
              }
              this.$emit('atualizaGrid')
              this.mostrarMsgModal('Registro alterado com sucesso!')
            })
            .catch((error) => {
              console.error(error)
              this.$message.error(error.response.data.error)
            })
        }
      },
      mostrarMsgModal(titulo) {
        this.$swal({
          title: 'Atenção!',
          text: titulo,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$emit('tomadorLoadingEvent', !this.loading)
          }
        })
      },
      capturarFoto() {
        this.toggleCamera()
        this.$refs['modal-foto'].show()
      },
      toggleCamera() {
        this.isCameraOpen = true
        this.startCameraStream()
      },
      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        })
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Desculpe mas seu navegador não tem suporte para capturar foto!')
          })
      },
      stopCameraStream() {
        const tracks = this.$refs.camera.srcObject.getTracks()
        tracks.forEach((track) => {
          track.stop()
        })
      },
      capture() {
        const FLASH_TIMEOUT = 50
        const self = this
        setTimeout(() => {
          const context = self.$refs.canvas.getContext('2d')
          context.drawImage(self.$refs.camera, 0, 0, self.canvasWidth, self.canvasHeight)
          const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
          self.isCameraOpen = false
          self.stopCameraStream()
        }, FLASH_TIMEOUT)
      },
      limparFoto() {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente limpar a foto?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete('cadastro/tomador/limparFoto', this.dados.id)
              .then((response) => {
                this.dados = { ...this.dados, foto: null }
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      confirmarFoto() {
        this.loading = true
        const self = this
        const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')

        if (this.dados.id) {
          useJwt
            .post('cadastro/tomador/cadastrarFoto', {
              foto: dataUrl,
              tomadorId: this.dados.id,
            })
            .then((response) => {
              this.dados = { ...this.dados, foto: response.data }
              this.$message.success('Foto capturada com sucesso!')
              this.loading = false
              this.$refs['modal-foto'].hide()
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
            })
        } else {
          this.dados = { ...this.dados, foto: { foto: dataUrl } }
          this.$message.success('Foto capturada com sucesso!')
          this.loading = false
          this.$refs['modal-foto'].hide()
        }
      },
      apresentaCortarFoto() {
        this.$refs['modal-editar-foto'].hide()
      },
      createEditSegurado() {
        this.$refs.formularioSegurado.validate().then((success) => {
          if (success) {
            let orgao
            if (this.segurado.orgao) {
              if (this.segurado.orgao.id) orgao = this.orgao.find((orgao) => orgao.id == this.segurado.orgao.id)
              else orgao = this.orgao.find((orgao) => orgao.id == this.segurado.orgao)
            }

            const dadosFormSegurado = {
              id: this.segurado.id ? this.segurado.id : null,
              matricula: this.segurado.matricula,
              orgao,
              orgaoId: orgao.id,
              situacaoPrevidenciaria: this.segurado.categoria,
              dataAdmissao: this.segurado.dataAdmissao ? formatarDataGlobal(this.segurado.dataAdmissao) : null,
              dataInicioBeneficio: this.segurado.dataInicioBeneficio
                ? formatarDataGlobal(this.segurado.dataInicioBeneficio)
                : null,
              dataFimBeneficio: this.segurado.dataFimBeneficio ? formatarDataGlobal(this.segurado.dataFimBeneficio) : null,
              remuneracao: formatarParaNumber(this.segurado.remuneracao),
              margemUtilizada: formatarParaNumber(this.segurado.margemUtilizada),
              margemDisponivel: formatarParaNumber(this.segurado.margemDisponivel),
              ehVitalicio: this.segurado.ehVitalicio,
              grupoFolhaId: this.segurado.grupoFolhaId,
              tipoBeneficio: this.segurado.tipoBeneficio,
              portadorMolestiaGrave: this.segurado.portadorMolestiaGrave,
              portadorDoencaIncapacitante: this.segurado.portadorDoencaIncapacitante,
              exonerado: this.segurado.exonerado,
              bloqueioJudicial: this.segurado.bloqueioJudicial,
              dataBloqueioJudicial: this.segurado.dataBloqueioJudicial
                ? formatarDataGlobal(this.segurado.dataBloqueioJudicial)
                : null,
            }

            let jaPossuiMatriculaNaGrid = false
            this.tomadorSegurados.forEach((rowSegurado) => {
              if (rowSegurado.matricula === dadosFormSegurado.matricula && rowSegurado.orgaoId === dadosFormSegurado.orgaoId) {
                jaPossuiMatriculaNaGrid = true
              }
            })

            if (!dadosFormSegurado.id && !jaPossuiMatriculaNaGrid) {
              this.tomadorSegurados.push(dadosFormSegurado)
            } else {
              this.tomadorSegurados.forEach((rowSegurado) => {
                if (
                  rowSegurado.id === dadosFormSegurado.id ||
                  (rowSegurado.matricula === dadosFormSegurado.matricula && rowSegurado.orgaoId === dadosFormSegurado.orgaoId)
                ) {
                  rowSegurado.matricula = dadosFormSegurado.matricula
                  rowSegurado.situacaoPrevidenciaria = dadosFormSegurado.situacaoPrevidenciaria
                  rowSegurado.remuneracao = dadosFormSegurado.remuneracao
                  rowSegurado.margemUtilizada = dadosFormSegurado.margemUtilizada
                  rowSegurado.margemDisponivel = dadosFormSegurado.margemDisponivel
                  rowSegurado.ehVitalicio = dadosFormSegurado.ehVitalicio
                  rowSegurado.grupoFolhaId = dadosFormSegurado.grupoFolhaId
                  rowSegurado.dataAdmissao = dadosFormSegurado.dataAdmissao
                  rowSegurado.dataInicioBeneficio = dadosFormSegurado.dataInicioBeneficio
                  rowSegurado.dataFimBeneficio = dadosFormSegurado.dataFimBeneficio
                  rowSegurado.orgaoId = dadosFormSegurado.orgao.id
                  rowSegurado.orgao = dadosFormSegurado.orgao
                  rowSegurado.tipoBeneficio = dadosFormSegurado.tipoBeneficio
                  rowSegurado.exonerado = dadosFormSegurado.exonerado
                  rowSegurado.bloqueioJudicial = dadosFormSegurado.bloqueioJudicial
                  rowSegurado.dataBloqueioJudicial = formatarData(dadosFormSegurado.dataBloqueioJudicial)
                }
              })
            }
            this.eraseFormSegurado()
            this.isCreateSegurado = true
          }
        })
      },
      eraseFormSegurado() {
        this.segurado.matricula = null
        this.segurado.orgao = null
        this.segurado.categoria = ''
        this.segurado.dataAdmissao = ''
        this.segurado.dataFimBeneficio = ''
        this.segurado.remuneracao = '0.00'
        this.segurado.margemUtilizada = '0.00'
        this.segurado.margemDisponivel = '0.00'
        this.segurado.grupoFolhaId = null
        this.segurado.bloqueioJudicial = false
        this.segurado.dataBloqueioJudicial = ''

        if (this.segurado.id) delete this.segurado.id

        this.$refs.formularioSegurado.reset()
      },
      carregarSegurados() {
        if (this.dados.id) {
          this.loading = true
          useJwt
            .get(`cadastro/tomador/GetSegurados/${this.dados.id}`)
            .then((response) => {
              this.listaSegurados = response.data
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
              this.$message.error('Erro ao buscar os dados do segurado')
            })
        }
      },
      validaDataSegurado(campo) {
        const erros = []

        if (campo == 'dataAdmissao' && this.segurado.dataAdmissao) {
          if (!this.validarData(this.segurado.dataAdmissao)) erros.push('A data de adminissão é inválida!')

          if (Date(this.segurado.dataAdmissao) > Date(this.segurado.dataFimBeneficio) && Date(this.segurado.dataFimBeneficio)) {
            erros.push('A data de admissão não pode ser superior a data fim do benefício!')
            this.segurado.dataAdmissao = ''
          }
          if (Date(this.segurado.dataAdmissao) < Date(this.dados.dataNascimento) && Date(this.dados.dataNascimento)) {
            erros.push('A data de admissão não pode ser inferior a data de nascimento do tomador!')
            this.segurado.dataAdmissao = ''
          }
        }

        if (campo == 'dataInicioBeneficio' && this.segurado.dataInicioBeneficio) {
          if (!this.validarData(this.segurado.dataInicioBeneficio)) erros.push('A data início do benefício é inválida!')
          if (Date(this.segurado.dataInicioBeneficio) < Date(this.dados.dataNascimento)) {
            erros.push('A data início do benefício não pode ser inferior a data de nascimento!')
            this.segurado.dataInicioBeneficio = ''
          }
        }

        if (campo == 'dataFimBeneficio' && this.segurado.dataFimBeneficio) {
          if (!this.validarData(this.segurado.dataFimBeneficio)) erros.push('A data fim do benefício é inválida!')
          if (Date(this.segurado.dataFimBeneficio) < Date(this.dados.dataNascimento)) {
            erros.push('A data fim do benefício não pode ser inferior a data de nascimento!')
            this.segurado.dataFimBeneficio = ''
          }
        }
        if (erros.length > 0) {
          erros.forEach((erro) => {
            this.$message.error(erro)
          })
        }
      },
      visualizarSegurado(segurado) {
        this.eraseFormSegurado()
        const remuneracao = !segurado.remuneracao
          ? '0.00'
          : String(segurado.remuneracao).includes('.')
          ? Number(segurado.remuneracao)
          : Number(segurado.remuneracao) * 100
        const margemUtilizada = !segurado.margemUtilizada
          ? '0.00'
          : String(segurado.margemUtilizada).includes('.')
          ? Number(segurado.margemUtilizada)
          : Number(segurado.margemUtilizada) * 100
        const margemDisponivel = !segurado.margemDisponivel
          ? '0.00'
          : String(segurado.margemDisponivel).includes('.')
          ? Number(segurado.margemDisponivel)
          : Number(segurado.margemDisponivel) * 100

        const formSegurado = {
          matricula: segurado.matricula,
          orgao: segurado.orgao,
          categoria: segurado.situacaoPrevidenciaria,
          dataAdmissao: segurado.dataAdmissao ? formatarData(segurado.dataAdmissao) : '',
          dataInicioBeneficio: segurado.dataInicioBeneficio ? formatarData(segurado.dataInicioBeneficio) : '',
          dataFimBeneficio: segurado.dataFimBeneficio ? formatarData(segurado.dataFimBeneficio) : '',
          remuneracao,
          margemUtilizada,
          margemDisponivel,
          ehVitalicio: segurado.ehVitalicio ? segurado.ehVitalicio : false,
          grupoFolhaId: segurado.grupoFolhaId,
          tipoBeneficio: segurado.tipoBeneficio,
          exonerado: segurado.exonerado ? segurado.exonerado : false,
          bloqueioJudicial: segurado.bloqueioJudicial,
          dataBloqueioJudicial: formatarData(segurado.dataBloqueioJudicial),
        }

        if (segurado.id) formSegurado.id = segurado.id

        this.segurado = formSegurado
        this.isCreateSegurado = false

        this.carregarGrupoFolha()
      },
      excluirSegurado(segurado) {
        const parametros = {
          seguradoId: segurado,
          institutoId: this.dados.institutoSelecionado,
        }

        useJwt
          .post('cadastro/tomador/GetPodeExcluirSegurado', parametros)
          .then((response) => {
            const { propostaQuantidade } = response.data
            const { simulacaoQuantidade } = response.data
            if (propostaQuantidade > 0 || simulacaoQuantidade > 0) {
              const simulacoes = `${simulacaoQuantidade} simulação(ões)`
              const propostas = `${propostaQuantidade} proposta(s)`
              const ligacao = propostaQuantidade > 0 && simulacaoQuantidade > 0 ? 'e' : ''
              const messagem = `Esse segurado já está associado à ${simulacaoQuantidade > 0 ? simulacoes : ''} ${ligacao} ${
                propostaQuantidade > 0 ? propostas : ''
              }`
              this.$message.error(messagem)
              return
            }

            if (segurado) {
              this.excluirSegurados.push(segurado)
              this.dados.excluirSegurados = this.excluirSegurados
            }

            this.tomadorSegurados = this.tomadorSegurados.filter((rowSegurado) => rowSegurado.id != segurado)
            this.dados.segurados = this.tomadorSegurados
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Não foi possível excluir segurado!')
          })
      },
      preencheMargemDisponivel() {
        const remuneracao = formatarParaNumber(this.segurado.remuneracao)
        if (isNumber(remuneracao)) {
          const valorRemuneracao = remuneracao
          const porcentagemMargemParametrizada = Number(Number(this.userData.Instituto.margemConsignavel) / 100).toFixed(2)
          this.segurado.margemDisponivel = Number(valorRemuneracao * porcentagemMargemParametrizada).toFixed(2)
        }
      },
      limpaGrupoFolha() {
        this.segurado.grupoFolhaId = null
      },
      async downloadCertidao() {
        const fileName = 'Certidao de Obito.pdf'
        const s3Key = this.certidaoObitoAnexada[0].s3Key
        this.isBusy = true
        await downloadFileAWS(s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      async carregarGridArquivos() {
        if (this.parametro.id)
          useJwt
            .get(`cadastro/tomador/GetDocumentosTomador/${this.parametro.id}`)
            .then((response) => {
              this.itemsArquivos = response.data
            })
            .catch((error) => {
              console.error(error)
            })
      },
      async downloadArquivo(item) {
        await getDownloadArquivoAws(item.s3Key)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .camera-canvas {
    text-align: center;
  }

  #modal-foto___BV_modal_footer_ {
    margin-left: auto;
    left: 0px;
    right: 0px;
    margin-right: auto;
  }

  label[for='btnCreateEditSegurado'],
  label[for='btnEraseFormSegurado'] {
    height: 17.39px;
  }

  .mr-05 {
    margin-right: 5px !important;
  }

  [dir] body #portadorDoencaIncapacitante > label.btn.btn-outline-primary.active > span,
  [dir] body #portadorMolestiaGrave > label.btn.btn-outline-primary.active > span,
  [dir] body #falecido > label.btn.btn-outline-primary.active > span,
  [dir] body #exonerado > label.btn.btn-outline-primary.active > span {
    color: #ecf0f1;
  }

  #portadorDoencaIncapacitante label.btn.btn-outline-primary.disabled.active,
  #portadorMolestiaGrave label.btn.btn-outline-primary.disabled.active,
  #falecido label.btn.btn-outline-primary.disabled.active,
  #exonerado label.btn.btn-outline-primary.disabled.active {
    background-color: #3949ab !important;
  }
  .b-form-group {
    color: #6e6b7b !important;
    opacity: 1 !important;
  }
  .custom-select {
    color: #6e6b7b !important;
    opacity: 1 !important;
  }
</style>
